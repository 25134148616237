import React from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/Container'
import SubscribeSection from '../components/SubscribeSection'
import 'antd/dist/antd.css'
import theme from '../styles/theme'

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: skewY(-8deg);
  transform-origin: 0;
  background: linear-gradient(150deg, #e8eaf6 15%, #e3f2fd 70%, #e1f5fe 90%);
  top: 0;
`

const Hero = styled.div`
  display: flex;
  height: 300px;
  align-items: center;
`

const HeroInner = styled.div`
  display: flex;
`

const HeroContent = styled.div`
  flex: 100%;
  color: ${theme.colors.blue};
  text-align: center;
`

const Section = styled.section`
  height: calc(100vh - 300px);
`

const EarlyAccessPage = () => (
  <Layout color="blue">
    <SEO title="Sift: Find and keep product market fit" overrideTitle />
    <header style={{ position: 'relative' }}>
      <Background />
      <Hero>
        <Container>
          <HeroInner>
            <HeroContent>
              <h1>
                <strong>Sign up for an invite</strong>
              </h1>
              <p style={{ fontSize: '1.3em' }}>
                Fill out the form below to get your invite.
              </p>
            </HeroContent>
          </HeroInner>
        </Container>
      </Hero>
    </header>
    <Section>
      <div id="request-invite">
        <SubscribeSection style={{ background: 'none' }} />
      </div>
    </Section>
  </Layout>
)

export default EarlyAccessPage
